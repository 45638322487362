@import '../../base.scss';

/* purgecss start ignore */
.content {
  p {
    margin-top: 20px;
    font-size: 17px;
    line-height: 28px;
    text-align: justify;
  }

  ul {
    list-style: circle inside none;
  }

  li > ul {
    padding-left: 20px;
  }

  li {
    font-size: 17px;
    line-height: 28px;
    text-align: justify;
  }

  span {
    font-size: 17px;
    line-height: 28px;
    text-align: justify;
  }


  a {
    @extend %anchor-base;
    color: #000000;
    font-weight: bold;

    &:before {
      background: #cc2229;
    }
  }
}
/* purgecss end ignore */
