@tailwind base;
@tailwind components;
@tailwind utilities;

@variants hover {
    .text-shadow {
        text-shadow: 0 0 4px rgba(0,0,0,0.20);
    }

}
