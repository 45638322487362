/* purgecss start ignore */
%anchor-base
{
  color: #ffffff;
  position: relative;
  text-decoration: none;
  transition: all 0.15s ease-out;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background: rgba(52, 194, 146, 1.0);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover {
    transition: all 0.15s ease-out;
    &:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}
/* purgecss end ignore */


