@import '../../../base.scss';

.item {
  width: 221px;

  h3 {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
  }

  p, ul, span {
    color: #cccccc;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 20px;
  }

  a {
    @extend %anchor-base
  }

}
