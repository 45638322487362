.container {
  height: 88px;
  padding: 21px 36px 0 18px;
}

.logoAgh {
  margin-top: 4px;
}

/*.siteName {*/
/*  font-weight: bold;*/
/*  font-size: 14px;*/
/*  display: block;*/
/*  line-height: 24px;*/
/*  margin: 16px 0 0 12px;*/
/*}*/

.langContainer {
  display: block;
  height: 25px;
  margin-right: 11px;
  margin-top: 17px;
}

.langContainer :not(:last-child){
  border-left: solid #cdcdcd 1px;
  border-right: solid #cdcdcd 1px;
}

.langContainer :last-child{
  border-right: solid #cdcdcd 1px;
}

.lang {
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  padding-left: 11px;
  padding-right: 11px;
}
